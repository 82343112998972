export const generateDocumentUrl = (params?: {
  documentId?: string;
  sectionId?: string;
  page?: number | string;
  view?: string;
}) => {
  const url = new URL('documents', window.location.origin);

  params?.documentId && url.searchParams.set('document-id', params.documentId);
  params?.sectionId && url.searchParams.set('section-id', params.sectionId);
  params?.page && url.searchParams.set('page', params.page.toString());
  params?.view && url.searchParams.set('view', params.view);

  return url.pathname + url.search;
};
