import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SearchData } from '../../types/RealtaDocument';
import { documentService } from '../../services/DocumentService';
import { navigate } from '@gatsbyjs/reach-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import SearchRow from './SearchRow';
import { getMsalInstance } from '../../utils/get-msal-instance';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const schema = () =>
  yup.object().shape({
    dataset: yup.string(),
    jdxs: yup.array().of(yup.string()),
    searchQuery: yup
      .string()
      .required('Search query is required')
      .test('notBlank', 'Search query is required', (value) => !!value),
  });

type DataInterface = {
  dataset: string;
  jdxs: string[];
  searchQuery: string;
};

const SearchComponent: React.FC<{ documentUuid?: string }> = ({ documentUuid }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const msalInstance = getMsalInstance();
  const queryParams = new URLSearchParams(location.search);
  const rootUrl = documentUuid ? `/documents` : '/search';

  const { handleSubmit, errors, register, control, setValue, watch } = useForm<DataInterface>({
    defaultValues: {
      dataset: '',
      jdxs: [],
      searchQuery: '',
    },
    resolver: yupResolver(schema()),
  });

  const [searchData, setSearchData] = React.useState<SearchData | null>(null);
  const [jurisdictions, setJurisdictions] = React.useState<string[]>([]);
  const [datasets, setDatasets] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState<boolean>(false);

  const isAllSelected = watch('jdxs').length > 0 && watch('jdxs').length === jurisdictions.length;

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const page =
          queryParams.get('searchPage') && queryParams.get('searchPage') ? parseInt(queryParams.get('searchPage')!) : 1;
        setPage(page - 1);

        const limit = queryParams.get('limit') ? parseInt(queryParams.get('limit')!) : 10;
        setRowsPerPage(limit);

        const searchQuery = queryParams.get('searchQuery') || undefined;
        if (searchQuery) setValue('searchQuery', searchQuery);

        if (!documentUuid) {
          const account = msalInstance.getActiveAccount();

          const dataset = queryParams.get('dataset') || 'All';
          if (dataset) setValue('dataset', dataset);

          const [dat, jdxs] = await Promise.all([
            documentService.getDatasets(account?.idTokenClaims?.roles),
            documentService.getJurisdictions(dataset && dataset !== 'All' ? [dataset] : undefined),
          ]);
          setDatasets(dat.map((j) => j.data));
          const formattedJurisdictions = jdxs.map((j) => j.data);
          setJurisdictions(formattedJurisdictions);

          const selectedJdxs =
            queryParams
              .get('jdxs')
              ?.split(',')
              .filter((jdx) => jdx && formattedJurisdictions.includes(jdx)) || undefined;
          if (selectedJdxs && selectedJdxs.length > 0) {
            setValue('jdxs', selectedJdxs);
          } else {
            setValue(
              'jdxs',
              jdxs.map((j) => j.data)
            );
          }

          if (!searchQuery) {
            return;
          }

          const response = await documentService.searchDocuments({
            jdxs: selectedJdxs,
            dataset: dataset && dataset !== 'All' ? dataset : undefined,
            searchQuery,
            page,
            limit,
            documentUuid,
          });
          if (!searchQuery) {
            return;
          }

          setSearchData(response);
        } else {
          const response = await documentService.searchDocuments({
            searchQuery,
            page,
            limit,
            documentUuid,
          });
          setSearchData(response);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch search data', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    init();
  }, [
    queryParams.get('jdxs'),
    queryParams.get('searchQuery'),
    queryParams.get('dataset'),
    queryParams.get('searchPage'),
    queryParams.get('limit'),
  ]);

  const handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    try {
      const formattedPage = newPage + 1;
      const url = new URL(rootUrl, window.location.origin);
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set('searchPage', formattedPage.toString());

      url.search = searchParams.toString();
      navigate(url.toString());
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to change page', { variant: 'error' });
    }
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const url = new URL(rootUrl, window.location.origin);
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('limit', event.target.value);
    searchParams.set('searchPage', '1');

    url.search = searchParams.toString();
    navigate(url.toString());
  };

  const handleSearch = async (data: DataInterface) => {
    try {
      const url = new URL(rootUrl, window.location.origin);

      const searchParams = new URLSearchParams(window.location.search);

      if (!documentUuid) {
        if (data.dataset && data.dataset !== 'All') {
          searchParams.set('dataset', data.dataset);
        } else {
          searchParams.delete('dataset');
        }

        if (data.jdxs.length > 0) {
          searchParams.set('jdxs', data.jdxs.join(','));
        } else {
          searchParams.delete('jdxs');
        }
      }

      if (data.searchQuery) {
        searchParams.set('searchQuery', data.searchQuery);
      } else {
        searchParams.delete('searchQuery');
      }

      url.search = searchParams.toString();
      navigate(url.toString());
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to search', { variant: 'error' });
    }
  };

  return (
    <>
      <Box display="flex" gap={2} m={2} component="form" onSubmit={handleSubmit(handleSearch)}>
        <Grid container spacing={2} width="100%">
          {!documentUuid && (
            <>
              <Grid item xs={4} md={4}>
                <FormControl sx={{ width: '100%' }} color="secondary">
                  <InputLabel id="select-dataset-label">Dataset</InputLabel>
                  <Controller
                    control={control}
                    name="dataset"
                    as={
                      <Select
                        labelId="select-dataset-label"
                        id="select-dataset"
                        label="Dataset"
                        error={!!errors.dataset}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {datasets.map((dataset, i) => (
                          <MenuItem key={i} value={dataset}>
                            {dataset}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
                <FormControl sx={{ width: '100%' }} color="secondary">
                  <InputLabel id="jdxs">Jurisdictions</InputLabel>
                  <Controller
                    control={control}
                    name="jdxs"
                    render={({ onChange, ...fields }) => (
                      <Select
                        {...fields}
                        labelId="jdxs"
                        id="jdxs-select"
                        multiple
                        renderValue={(selected: any) => selected.join(', ')}
                        error={!!errors.jdxs}
                        input={<OutlinedInput label="Jurisdictions" />}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value[value.length - 1] === 'all') {
                            setValue('jdxs', watch('jdxs').length === jurisdictions.length ? [] : jurisdictions);
                            return;
                          }
                          onChange(e);
                        }}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                          color="secondary"
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: classes.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={watch('jdxs').length > 0 && watch('jdxs').length < jurisdictions.length}
                              color="secondary"
                            />
                          </ListItemIcon>
                          <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                        </MenuItem>
                        {jurisdictions.map((jurisdiction) => (
                          <MenuItem key={jurisdiction} value={jurisdiction}>
                            <Checkbox checked={watch('jdxs').includes(jurisdiction)} color="secondary" />
                            <ListItemText primary={jurisdiction} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={documentUuid ? 12 : 4} md={documentUuid ? 12 : 4}>
            <TextField
              id="outlined-required"
              label="Query"
              name="searchQuery"
              fullWidth
              color="secondary"
              inputRef={register}
              error={!!errors.searchQuery}
              helperText={errors.searchQuery?.message}
            />
          </Grid>
        </Grid>
        <Button color="secondary" variant="contained" type="submit" sx={{ height: 56 }}>
          Search
        </Button>
      </Box>
      {loading ? (
        <Box width="100%" height={400} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={80} color="secondary" />
        </Box>
      ) : (
        !!searchData?.items.length && (
          <Box px={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ minWidth: 240 }}>Display Name</StyledTableCell>
                    <StyledTableCell>Content</StyledTableCell>
                    <StyledTableCell sx={{ minWidth: 240 }}>Source Path</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchData?.items.map((record, i) => (
                    <SearchRow item={record} key={i} keyword={watch('searchQuery')} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={searchData.total || 0}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              sx={{ my: 2, display: 'flex', justifyContent: 'center' }}
              color="secondary"
            />
          </Box>
        )
      )}
    </>
  );
};

export default SearchComponent;
