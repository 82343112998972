import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { highlightByQuery } from 'js-solr-highlighter';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';

interface HighlighterProps {
  text: string;
  keyword: string;
}

const Highlighter: React.FC<HighlighterProps> = ({ text, keyword }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [truncatedText, setTruncatedText] = useState<string[]>([]);
  const { width } = useWindowSize();

  useEffect(() => {
    try {
      const getTruncatedText = (text: string) => {
        let remainingLength = width - 960 > 0 ? width - 960 : 400;
        const truncatedParts: string[] = [];

        if (text.length > remainingLength) {
          truncatedParts.push(text.substring(0, remainingLength));
        }

        return truncatedParts;
      };

      const highlightedParts = getTruncatedText(text);
      setTruncatedText(highlightedParts);
    } catch (error) {
      console.warn(error);
      enqueueSnackbar('Error highlighting text', { variant: 'error' });
    }
  }, [text]);

  const highlightText = (keyword: string, text: string) => {
    try {
      return parse(highlightByQuery(keyword, text));
    } catch (error) {
      return text;
    }
  };

  return (
    <Tooltip title={text} placement="top">
      <div>
        {truncatedText.length > 0 ? (
          <>
            {truncatedText.map((part, index) => (
              <span key={index}>{highlightText(keyword, part)}</span>
            ))}
            {truncatedText.join(' ').length < text.length && '...'}
          </>
        ) : (
          highlightText(keyword, text)
        )}
      </div>
    </Tooltip>
  );
};

export default Highlighter;
